import React from 'react';

const SvgPlayStore = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x={0}
      y={0}
      viewBox="0 0 646 191.4"
      style={{ enableBackground: 'new 0 0 646 191.4' }}
      xmlSpace="preserve"
      width={202.8}
      height={60}
    >
      <defs>
        <path id="SVGID_1_" d="M0 0h646v191.4H0z" />
      </defs>
      <clipPath id="SVGID_00000120521012345663964370000016139441983121077143_">
        <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
      </clipPath>
      <g style={{ clipPath: 'url(#SVGID_00000120521012345663964370000016139441983121077143_)' }}>
        <defs>
          <path id="SVGID_00000008865900332716549850000008093011865911307397_" d="M0 0h646v191.4H0z" />
        </defs>
        <clipPath id="SVGID_00000070836182273089513000000013176646553382213513_">
          <use xlinkHref="#SVGID_00000008865900332716549850000008093011865911307397_" style={{ overflow: 'visible' }} />
        </clipPath>
        <path
          style={{ clipPath: 'url(#SVGID_00000070836182273089513000000013176646553382213513_)', fill: '#12100b' }}
          d="M622.1 191.4H23.9C10.8 191.4 0 180.6 0 167.5V23.9C0 10.8 10.8 0 23.9 0H622c13.2 0 24 10.8 24 23.9v143.6c0 13.1-10.8 23.9-23.9 23.9"
        />
        <path
          style={{ clipPath: 'url(#SVGID_00000070836182273089513000000013176646553382213513_)', fill: '#afacab' }}
          d="M622.1 3.8c11.1 0 20.1 9 20.1 20.1v143.6c0 11.1-9 20.1-20.1 20.1H23.9c-11.1 0-20.1-9-20.1-20.1V23.9c0-11.1 9-20.1 20.1-20.1h598.2zm0-3.8H23.9C10.8 0 0 10.8 0 23.9v143.6c0 13.2 10.8 23.9 23.9 23.9H622c13.2 0 23.9-10.8 23.9-23.9V23.9C646 10.8 635.2 0 622.1 0"
        />
        <path
          style={{ clipPath: 'url(#SVGID_00000070836182273089513000000013176646553382213513_)', fill: '#fff' }}
          d="M326 104.1c-11.3 0-20.4 8.6-20.4 20.4 0 11.7 9.2 20.4 20.4 20.4 11.3 0 20.4-8.6 20.4-20.4.1-11.9-9.1-20.4-20.4-20.4m0 32.7c-6.2 0-11.5-5.1-11.5-12.3 0-7.3 5.3-12.3 11.5-12.3s11.5 5 11.5 12.3c0 7.2-5.3 12.3-11.5 12.3m-44.5-32.7c-11.3 0-20.4 8.6-20.4 20.4 0 11.7 9.2 20.4 20.4 20.4 11.3 0 20.4-8.6 20.4-20.4 0-11.9-9.2-20.4-20.4-20.4m0 32.7c-6.2 0-11.5-5.1-11.5-12.3 0-7.3 5.3-12.3 11.5-12.3s11.5 5 11.5 12.3c0 7.2-5.4 12.3-11.5 12.3m-53-26.5v8.6h20.7c-.6 4.9-2.2 8.4-4.7 10.9-3 3-7.7 6.3-16 6.3-12.7 0-22.7-10.2-22.7-23 0-12.7 9.9-23 22.7-23 6.9 0 11.9 2.7 15.6 6.2l6.1-6.1c-5.2-4.9-12-8.7-21.7-8.7-17.4 0-32.1 14.2-32.1 31.6 0 17.4 14.6 31.6 32.1 31.6 9.4 0 16.5-3.1 22-8.9 5.7-5.7 7.5-13.7 7.5-20.2 0-2-.2-3.9-.5-5.4h-29zm216.8 6.7c-1.7-4.6-6.9-13-17.4-13-10.5 0-19.2 8.2-19.2 20.4 0 11.4 8.6 20.4 20.2 20.4 9.3 0 14.7-5.7 17-9l-6.9-4.6c-2.3 3.4-5.5 5.6-10 5.6s-7.8-2.1-9.9-6.2l27.2-11.3-1-2.3zm-27.8 6.8c-.2-7.9 6.1-11.9 10.6-11.9 3.6 0 6.6 1.8 7.6 4.3l-18.2 7.6zm-22.1 19.8h8.9V83.7h-8.9v59.9zm-14.7-35h-.3c-2-2.4-5.9-4.6-10.7-4.6-10.2 0-19.5 8.9-19.5 20.4 0 11.4 9.3 20.3 19.5 20.3 4.9 0 8.7-2.2 10.7-4.6h.3v2.9c0 7.8-4.2 11.9-10.9 11.9-5.5 0-8.9-3.9-10.3-7.2l-7.8 3.2c2.2 5.4 8.2 12 18 12 10.5 0 19.4-6.2 19.4-21.2v-36.5h-8.5v3.4zm-10.2 28.2c-6.2 0-11.3-5.2-11.3-12.3 0-7.2 5.2-12.4 11.3-12.4 6.1 0 10.9 5.2 10.9 12.4 0 7.1-4.8 12.3-10.9 12.3m116.7-53.1h-21.4v59.8h8.9v-22.7h12.5c9.9 0 19.6-7.2 19.6-18.6 0-11.3-9.7-18.5-19.6-18.5m.2 28.9h-12.7V92.1h12.7c6.7 0 10.5 5.5 10.5 10.3 0 4.5-3.8 10.2-10.5 10.2m55.2-8.6c-6.5 0-13.2 2.8-15.9 9.2l7.9 3.3c1.7-3.3 4.8-4.4 8.2-4.4 4.6 0 9.3 2.8 9.4 7.7v.6c-1.6-.9-5.1-2.3-9.3-2.3-8.5 0-17.2 4.7-17.2 13.5 0 8 7 13.2 14.9 13.2 6 0 9.3-2.7 11.4-5.8h.3v4.6h8.6v-22.9c-.2-10.8-8.1-16.7-18.3-16.7m-1.1 32.8c-2.9 0-7-1.5-7-5.1 0-4.6 5.1-6.4 9.5-6.4 3.9 0 5.8.8 8.2 2-.8 5.5-5.5 9.5-10.7 9.5m50.6-31.5-10.2 25.9h-.3L571 105.3h-9.6l15.9 36.2-9.1 20.2h9.3l24.6-56.4h-10zm-80.4 38.3h8.9V83.7h-8.9v59.9z"
        />
        <path style={{ clipPath: 'url(#SVGID_00000070836182273089513000000013176646553382213513_)', fill: '#e43629' }} d="M99.1 92.9 48.2 147c1.6 5.9 6.9 10.2 13.3 10.2 2.5 0 4.9-.7 7-1.9l.2-.1 57.3-33.1-26.9-29.2z" />
        <path style={{ clipPath: 'url(#SVGID_00000070836182273089513000000013176646553382213513_)', fill: '#f9bd00' }} d="m150.7 83.7-24.8-14.4L98 94.2l28 28 24.6-14.2c4.3-2.3 7.2-6.9 7.2-12.1 0-5.3-2.8-9.8-7.1-12.2" />
        <path style={{ clipPath: 'url(#SVGID_00000070836182273089513000000013176646553382213513_)', fill: '#587dbe' }} d="M48.2 44.4c-.3 1.1-.5 2.3-.5 3.5v95.6c0 1.2.2 2.4.5 3.5l52.7-52.7-52.7-49.9z" />
        <path style={{ clipPath: 'url(#SVGID_00000070836182273089513000000013176646553382213513_)', fill: '#30a043' }} d="m99.5 95.7 26.4-26.4-57.3-33.2c-2.1-1.2-4.5-2-7.1-2-6.4 0-11.7 4.3-13.3 10.2l51.3 51.4z" />
      </g>
      <g style={{ clipPath: 'url(#SVGID_00000120521012345663964370000016139441983121077143_)' }}>
        <path
          fill="#fff"
          d="M201.2 61.7c-1.7-1.2-2.9-3-3.6-5.2l4.2-1.7c.4 1.5 1.1 2.7 2 3.4.9.7 2 1.1 3.2 1.1 1.5 0 2.6-.5 3.5-1.5.9-1 1.3-2.3 1.3-4V33h4.6v20.5c0 2.1-.4 3.9-1.2 5.4-.8 1.5-1.9 2.6-3.3 3.4-1.4.8-3 1.2-4.8 1.2-2.2 0-4.2-.6-5.9-1.8zM223.8 33h18.1v4.3h-13.5v8.4h12.2V50h-12.2v8.4h13.5v4.3h-18.1V33zM254.4 37.4H246V33h21.3v4.3H259v25.5h-4.6V37.4zM270.8 58.2l15.6-20.8h-14.9V33h20.2v4.7l-15.5 20.8h15.7v4.3h-21.1v-4.6zM304.1 37.4h-8.4V33H317v4.3h-8.3v25.5h-4.6V37.4zM331.2 33h11.2c1.6 0 3.1.3 4.4 1 1.4.7 2.4 1.6 3.2 2.9.8 1.2 1.2 2.5 1.2 4s-.4 2.8-1.1 3.9c-.7 1.1-1.7 1.9-2.9 2.5v.2c1.6.5 2.8 1.4 3.7 2.6.9 1.2 1.4 2.7 1.4 4.3 0 1.6-.4 3.1-1.3 4.4-.8 1.3-2 2.2-3.4 3-1.4.7-3 1.1-4.7 1.1h-11.7V33zm11 12.6c1.4 0 2.5-.4 3.3-1.3.8-.8 1.2-1.8 1.2-2.9 0-1.1-.4-2.1-1.2-2.9-.8-.8-1.9-1.2-3.2-1.2h-6.5v8.3h6.4zm.7 13c1.4 0 2.6-.4 3.5-1.3.9-.9 1.3-1.9 1.3-3.1 0-1.2-.5-2.3-1.4-3.2-.9-.9-2.1-1.3-3.6-1.3h-6.9v9h7.1zM358.3 33h18.1v4.3h-13.5v8.4H375V50h-12.2v8.4h13.5v4.3h-18.1V33zM382.8 33h4.6v29.8h-4.6V33z"
        />
      </g>
    </svg>
  );
};

export default SvgPlayStore;
