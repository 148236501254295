import React from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  imageData: IGatsbyImageData;
  alt: string;
  className?: string;
}

const Image: React.FC<Props> = ({ imageData, alt, className }) => {
  const imageGatsby = getImage(imageData);

  if (imageGatsby) {
    return <GatsbyImage image={imageGatsby} alt={alt} title={alt} className={`rounded-xl ${className ? className : ''}`} />;
  }
  return null;
};

export default Image;
