// Packages
import React from 'react';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ChevronRightIcon, ChevronLeftIcon, ExternalLinkIcon } from '@heroicons/react/solid';

// Types
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';

// Components
import Container from '~components/common/Container';
import P from '~components/typography/P';
import { graphql, useStaticQuery } from 'gatsby';
import Button, { buttonClassBig, buttonClassPrimary, buttonClassSetup, buttonClassSmall } from '~components/common/Button';
import H2 from '~components/typography/H2';
import Grid from '~components/common/Grid';
import Disclosure from '~components/common/Disclosure';
import { customHeadingClasses } from '../../utils/helpers';

const Testmonials = () => {
  const data = useStaticQuery(graphql`
    query ComponentTeamMembers {
      allFile(filter: { relativePath: { regex: "/^components/Testmonials/" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
          name
          id
        }
      }
    }
  `);

  const memberImageData = data.allFile.nodes;

  // member name and image (slugified) needs to have the same name in order to match
  const members = [
    {
      name: 'Verena Dislich',
      title: 'Physiotherapeutin',
      imageData: memberImageData.find((image) => image?.name === 'Verena-Dislich')?.childImageSharp?.gatsbyImageData,
      challenge:
        'Was für ein Mist! Verena arbeitet selbstständig als Physiotherapeutin. Sie hat 2 Kinder und trägt Verantwortung für 5 Mitarbeitende und erschöpft sich zunehmend. Sie selber steht auch noch an der Bank, merkt aber, dass ihre Kräfte schwinden. Sie wird immer häufiger krank, ist schlecht gelaunt und kann sich zu nichts mehr aufraffen. Auszufallen oder eine Auszeit zu nehmen kommt auf keinen Fall in Frage. Gleichzeitig muss sie die Fassade waren, die Stimmung, Motivation und den Vertrieb am Laufen halten. Auch einer der Gründe, weshalb sie auf dem Zahnfleisch geht. Sie fühlt sich entfremdet.',
      solution:
        'Trotz ihrer Arbeit als Physiotherapeutin und begeisterte Sportlerin hat Verena den Zugang zu sich selbst komplett verloren. Daher war der erste Schritt einen Zugang zu sich neu zu entdecken. Ein annehmender und achtsamer Umgang mit ihren Stressreaktionen und das Schaffen von Ritualen und Gewohnheiten führten zu ersten guten Ergebnissen. Die regelmäßige Morgenmeditation wurde ein fester Bestandteil ihres Tagesstarts und gab ihr Kraft für den Tag.',
      result:
        'Verena ist wieder einsatzbereit und die Praxis läuft sehr gut. Mehr noch, durch die Einzelgespräche ist sie wieder begeistert dabei und hat ihre Prioritäten in der Praxis neu gesetzt. Trotz unvermeidbarer Herausforderungen die das berufliche so mit sich bringen, bleibt sie in Ihrer Kraft, motiviert und begeistert. Die Gewohnheit zu meditieren hat sie beibehalten und investiert wieder mehr Zeit für sich und ihren Sport. Verena hat sich selbst wieder eine Priorität gegeben.',
      link: 'https://www.verena-dislich.de/verena-dislich/'
    },
    {
      name: 'Oliver',
      title: 'Inhaber einer Werbeagentur',
      imageData: memberImageData.find((image) => image?.name === 'Oliver')?.childImageSharp?.gatsbyImageData,
      challenge:
        'Oliver hat sich bei der Arbeit voll reingehängt. Die Auftragslage war einfach bombastisch. Er arbeitete weit mehr als 50 Stunden. Sehr zum Leidwesen seiner Partnerin und seiner Gesundheit, die gemeinsam durchbrannten. Schlafprobleme, Rückenschmerzen und zunehmende Erschöpfung waren die Folge. Die Krone der Erschöpfung, war eine ausgewachsene Panikattacke bei der Arbeit.',
      solution:
        'Oliver hat falsche Überzeugungen über sich und seine Arbeit entdeckt und verändert. Eine neue Arbeitsstruktur und ein ritueller Feierabend ermöglichten es ihm, wieder durchzuschlafen. In der Zusammenarbeit ist ein individuelles Entspannungsnotfallset entstanden, dass Oliver beim Einschlafen und aufkeimender Panik hilft. ',
      result:
        'Oliver arbeitet immernoch viel, allerdings deutlich weniger als zuvor. Nach der Arbeit sitzt er für wenige Augenblicke im Auto und macht seine Übungen. Er hat Handy und Notebook freie Zeiten und arbeitet an seinen sechs Lebenssäulen. Die dysfunktionalen Überzeugungen über seine Arbeit konnte er ablegen und fühlt sich nun auch gut, wenn mal etwas liegen bleibt.'
    },
    {
      name: 'Torben',
      title: 'Grundschullehrer',
      imageData: memberImageData.find((image) => image?.name === 'Torben')?.childImageSharp?.gatsbyImageData,
      challenge:
        'Das hat Torben sich anders vorgestellt. Als er sein Studium zum Lehrer begonnen hat, wollte er mit Jugendlichen seine Begeisterung zu Sport und Sprachen teilen. Doch die Arbeit entsprach garnicht seinen Erwartungen. Er hat soviel investiert. Es wäre doch blöd, dass wegzuschmeißen oder?  Der innere Konflikt zeigte sich in Form von Kieferspannung, die Kopfschmerzen und Geräuschempfindlichkeit nach sich zog. Schlussendlich blieb nur noch die Krankschreibung durch Erschöpfung.',
      solution:
        'Das akute Symptom des Knirschens konnte mit wenigen Übungen etwas gelindert werden. Doch die Ursache war das wo wir ran mussten. Torbens eigentliche Leidenschaft galt schon immer dem Sport. Er träumt von einer Selbstständigkeit als Personal Trainer. In 1:1 Coachings erarbeiten (sind noch immer im Prozess), eine Exitstrategie, um einen Weg aus der Sicherheit, hin zum kalkulierten Risiko zu gehen.',
      result:
        'Torben konnte seine innere Haltung verändern und hat es sich erlaubt bei Krankheit wirklich zu Hause zu bleiben. Die realistische Einschätzung seiner Energie dokumentiert Torben über ein Energietagebuch, das es ihm ermöglicht, seine Gesundheit im Blick zu halten. Durch die entwickelte Perspektive schöpft er neue Kraft und bestreitet seinen Alltag heute besser als zuvor. Zeitgleich arbeitet er an seinem Plan, den er dieses Jahr noch umsetzen möchte.'
    },
    {
      name: 'Nora',
      title: 'Studentin',
      imageData: memberImageData.find((image) => image?.name === 'Nora')?.childImageSharp?.gatsbyImageData,
      challenge:
        'Erneut nur 13 Punkte in der Klausur. Nora ist enttäuscht. Wochenlang hat sie sich auf die Prüfung vorbereitet und es nun doch vergeigt. Nora leidet wie viele Menschen unter starken inneren Ansprüchen und dem Wunsch es perfekt machen zu wollen. Das das eine total überzogene Ansicht ist, dass ist ihr eigentlich bewusst. Doch freuen kann sie sich über die 13 Punkte trotzdem nicht. Nora fällt es schwer Pausen zu machen, oder auch nur mal für eine Stunde abzuschalten. Sie merkt, dass ihre Energie langsam erschöpft ist.',
      solution:
        'Die Arbeit an unvorteilhaften Glaubenssätzen und inneren Antreibern war ein großer Teil der Zusammenarbeit. Nora erkundete über Meditationen oder auch das Videoprogramm "Upgrade your Mind" ihre Haltung, reflektierte ihre Art zu denken und probierte Methoden, wie die Pomodorotechnik und Genusstraining aus, die es ihr ermöglichten, Pausen in ihren Alltag zu integrieren.',
      result:
        'Zwar ist Nora immer noch sehr strebsam und fleißig, doch sie schafft es deutlich besser ihre Energie im Blick zu behalten und Pausen zu integrieren. Nach und nach entwickelt und verfestigt sie die Überzeugung, dass sie auch wertvoll ist, wenn sie etwas unperfekt belässt, was ihr persönlich viel Gelassenheit schenkt. Außerdem probiert sie immer ieder neue Dinge aus, um für sich einen Alltag zum Schreibtisch zu finden. '
    }
  ];
  return (
    <Container>
      <h2 className={`mb-8 md:mb-16 lg:mb-32 ${customHeadingClasses.h2} text-center text-brand-gray`}>Kundenfeedback</h2>

      <CarouselProvider visibleSlides={1} totalSlides={members.length} step={1} naturalSlideWidth={320} naturalSlideHeight={100} infinite isIntrinsicHeight interval={5000}>
        <Slider>
          {members.map((member, i) => {
            const memberImage = member.imageData ? getImage(member.imageData) : null;
            return (
              <Slide index={i} key={member.name} className="px-2">
                <>
                  <Grid cols={3} className="mx-auto max-w-6xl rounded-xl bg-gray-100 p-3">
                    <div className="md:col-span-2">
                      <p className={`${customHeadingClasses.h3} text-brand-gray`}>
                        {member.name} <br />
                        <span className={`${customHeadingClasses.h4}`}>{member.title}</span>
                      </p>
                      {member.link ? (
                        <Button
                          href="https://www.verena-dislich.de/verena-dislich/"
                          small
                          type="white"
                          target="_blank"
                          text={
                            <>
                              Mehr zu {member.name} <ExternalLinkIcon className="ml-2 inline-block h-6 w-6 text-gray-700" />
                            </>
                          }
                          className="mt-4 block"
                        />
                      ) : null}
                      <div className="mt-8 space-y-4">
                        <Disclosure title="Herausforderung">
                          <P>{member.challenge}</P>
                        </Disclosure>
                        <Disclosure title="Lösung">
                          <P>{member.solution}</P>
                        </Disclosure>
                        <Disclosure title="Ergebnis">
                          <P>{member.result}</P>
                        </Disclosure>
                      </div>

                      <div className="mt-8 flex space-x-3">
                        <ButtonBack aria-label="Vorherige Bewertung" className={`${buttonClassSetup} ${buttonClassPrimary} ${buttonClassSmall} p-2`}>
                          <ChevronLeftIcon className="h-6 w-6" /> Zurück
                        </ButtonBack>
                        <ButtonNext aria-label="Nächste Bewertung" className={`${buttonClassSetup} ${buttonClassPrimary} ${buttonClassSmall} p-2`}>
                          Weiter <ChevronRightIcon className="h-6 w-6" />
                        </ButtonNext>
                      </div>
                    </div>

                    <div className="">{memberImage ? <GatsbyImage image={memberImage} alt={`Bild vom Testmonial: ${member.name ? member.name : 'Anonym'}`} className="mb-2 w-full rounded-xl" /> : null}</div>
                  </Grid>
                </>
              </Slide>
            );
          })}
        </Slider>
      </CarouselProvider>
    </Container>
  );
};

export default Testmonials;
