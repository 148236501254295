import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import FaqItem from '~components/common/FaqItem';
import parse from 'html-react-parser';

const Faq = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allFaq {
          nodes {
            text
            frage
            id
          }
        }
      }
    `
  );

  return (
    <dl className="space-y-3">
      {data.allFaq.nodes.map((faq) => {
        return <FaqItem title={faq.frage} text={parse(faq.text)} key={faq.id} />;
      })}
    </dl>
  );
};

export default Faq;
