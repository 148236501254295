import React from 'react';

export const svgs = {
  divider: (className) => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ enableBackground: 'new 0 0 432.1 35.7' }} viewBox="0 0 432.1 35.7" className={`inline-block ${className ? className : ''}`}>
      <path
        fill="currentColor"
        d="M432 17.4H35.4C35.2 7.8 27.3 0 17.7 0 7.9 0 0 7.9 0 17.7s7.9 17.7 17.7 17.7c9.6 0 17.5-7.8 17.7-17.4H432v-.6zM17.7 34.7c-9.4 0-17-7.6-17-17s7.6-17 17-17c9.3 0 16.9 7.5 17 16.7h-17v.6h17c-.1 9.3-7.7 16.7-17 16.7z"
      />
    </svg>
  ),
  dividerVertical: (className) => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" style={{ enableBackground: 'new 0 0 36.3 433' }} viewBox="0 0 36.3 433" className={`inline-block ${className ? className : ''}`}>
      <path
        fill="currentColor"
        d="M18.3 432.7V36.1c9.6-.2 17.4-8 17.4-17.7C35.7 8.6 27.8.7 18 .7S.3 8.6.3 18.4c0 9.6 7.8 17.5 17.4 17.7v396.6h.6zM1 18.4c0-9.4 7.6-17 17-17s17 7.6 17 17c0 9.3-7.5 16.9-16.7 17v-17h-.6v17C8.4 35.2 1 27.7 1 18.4z"
      />
    </svg>
  )
};
