import { RefObject, useEffect } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

// Update the interface to include a ref object
export interface AnimationConfigItem {
  ref: RefObject<HTMLElement>;
  animationOptions: gsap.TweenVars;
  scrollTriggerOptions?: ScrollTrigger.Vars;
  delay?: number;
}

const defaultScrollTriggerOptions = {
  start: 'top bottom', // Adjusted to trigger as soon as the element is in the viewport
  toggleActions: 'play none none none'
};

export const animations = {
  scaleIn: {
    duration: 2,
    opacity: 50,
    scale: 1.1,
    ease: 'power1.out'
  },
  fadeInLeft: {
    duration: 1,
    x: -100,
    opacity: 0,
    ease: 'power1.out'
  },
  fadeInRight: {
    duration: 1,
    x: 100,
    opacity: 0,
    ease: 'power1.out'
  },
  fadeInUp: {
    duration: 1,
    y: 100,
    opacity: 0,
    ease: 'power1.out'
  },
  fadeInDown: {
    duration: 1,
    y: -100,
    opacity: 0,
    ease: 'power1.out'
  }
};

const useAnimations = (config: AnimationConfigItem[]): void => {
  useEffect(() => {
    config.forEach(({ ref, animationOptions, scrollTriggerOptions, delay }) => {
      const el = ref.current;
      if (!el) return;

      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          ...defaultScrollTriggerOptions,
          ...scrollTriggerOptions
        }
      });

      timeline.from(el, { ...animationOptions, delay });
    });

    // Cleanup function to kill GSAP animations and ScrollTriggers
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      config.forEach(({ ref }) => {
        const el = ref.current;
        if (el) {
          gsap.killTweensOf(el);
        }
      });
    };
  }, [config]); // Depend on config to re-initialize when config changes
};

export default useAnimations;
